import "smartwizard/dist/css/smart_wizard_all.css";
import "smartwizard/dist/js/jquery.smartWizard.min.js";
import '../../scss/smartwizard.scss';
import '../../js/validate/jquery.validate-1.19.0.min.js';


$(function() {

    // Add Multi-step From Validation
    let step_validator = $(".smartwizard").parent().validate({
        onchange: function (element, event) {
          $(".tab-pane:visible").parent().find("label.error").remove();
          $(".tab-pane:visible").parent().animate({height: $(".tab-pane:visible").outerHeight()});
        },
        onfocusout: function (element, event) {
          $(".tab-pane:visible").parent().find("label.error").remove();
          $(".tab-pane:visible").parent().animate({height: $(".tab-pane:visible").outerHeight()});
        },
        onclick: function (element, event) {
            $(".tab-pane:visible").parent().animate({height: $(".tab-pane:visible").outerHeight()});
        },
        onfocus: function (element, event) {
            $(".tab-pane:visible").parent().animate({height: $(".tab-pane:visible").outerHeight()});
        },
    });

    // Smart Wizard Init
    $('.smartwizard').smartWizard({
        theme: 'dots',
        enableUrlHash: false,
        transition: {
            animation: 'css', // Animation effect on navigation, none|fade|slideHorizontal|slideVertical|slideSwing|css(Animation CSS class also need to specify)
            speed: '400', // Animation speed. Not used if animation is 'css'
            easing: '', // Animation easing. Not supported without a jQuery easing plugin. Not used if animation is 'css'
            prefixCss: 'animate__animated animate__faster',
            fwdShowCss: 'animate__slideInRight',
            fwdHideCss: 'animate__slideOutLeft',
            bckShowCss: 'animate__slideInLeft',
            bckHideCss: 'animate__slideOutRight',
        },
        toolbar: {
            position: 'bottom', // none|top|bottom|both
            showNextButton: false, // show/hide a Next button
            showPreviousButton: false, // show/hide a Previous button
            extraHtml: '' // Extra html to show on toolbar
        },
        anchor: {
            enableNavigation: false, // Enable/Disable anchor navigation
            enableNavigationAlways: false, // Activates all anchors clickable always
            enableDoneState: false, // Add done state on visited steps
            markPreviousStepsAsDone: false, // When a step selected by url hash, all previous steps are marked done
            unDoneOnBackNavigation: false, // While navigate back, done state will be cleared
            enableDoneStateNavigation: false // Enable/Disable the done state navigation
        },
        keyboard: {
            keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
            keyLeft: [37], // Left key code
            keyRight: [39] // Right key code
        },
    });

    // Navigate previous
    $(".smartwizard").find(".step-prev").on("click", function (e) {
        e.stopImmediatePropagation();
        $('.smartwizard').smartWizard("prev");
        return true;
    });

    // Navigate next
    $(".smartwizard").find(".step-next").on("click", function (e) {
        e.stopImmediatePropagation();
        $('.smartwizard').smartWizard("next");
        return true;
    });

    // Next on enter keyboard
    $(document).on('keypress',function(e) {
        if(e.which == 13) {
          if($('.stepForm .tab-pane:visible input[type=submit]').length > 0) {
            $('.stepForm .tab-pane:visible input[type=submit]').trigger("click");
            return false;
          }else {
            e.stopImmediatePropagation();
            if($('.stepForm').length > 0) {
              $('.stepForm').smartWizard("next");
            }
            return true;
          }
        }
    });

    // Custom trasition to add class for active step
    $.fn.smartWizard.transitions.css = (elmToShow, elmToHide, stepDirection, wizardObj, callback) => {
        if (wizardObj.options.transition.fwdHideCss.length == 0 || wizardObj.options.transition.bckHideCss.length == 0 ) { callback(false); return; }

        // CSS Animation
        const animFn = (elm, animation, cb) => {
            if (!animation || animation.length == 0) cb();

            elm.addClass(animation).one("animationend", (e) => {
                $(e.currentTarget).removeClass(animation);
                cb();
            });
            elm.addClass(animation).one("animationcancel", (e) => {
                $(e.currentTarget).removeClass(animation);
                cb('cancel');
            });
        };

        const showCss = wizardObj.options.transition.prefixCss + ' ' + (stepDirection == 'backward' ? wizardObj.options.transition.bckShowCss : wizardObj.options.transition.fwdShowCss);
        if (elmToHide) {
            const hideCss = wizardObj.options.transition.prefixCss + ' ' + (stepDirection == 'backward' ? wizardObj.options.transition.bckHideCss : wizardObj.options.transition.fwdHideCss);
            animFn(elmToHide, hideCss, () => {
                elmToHide.removeClass("active");
                elmToHide.hide();

                animFn(elmToShow, showCss, () => {
                    callback();
                });
                elmToShow.addClass("active");
                elmToShow.show();
            });
        } else {
            animFn(elmToShow, showCss, () => {
                callback();
            });
            elmToShow.addClass("active");
            elmToShow.show();
        }
    }

    // Show step for multiple steps in step
    $(".smartwizard").on("showStep", function (e, anchorObject, stepIndex, stepDirection, stepPosition) {

        // Scroll top when step load
        if(stepIndex != 0) {
            $('html, body').animate({
                scrollTop: $(".intuitive-forms").offset().top - 50
            }, 1000);
        }

        if(stepIndex == 0 && stepDirection == "backward") {
            $('html, body').animate({
                scrollTop: $(".intuitive-forms").offset().top - 50
            }, 1000);
        }

        let step_active = $(".smartwizard .tab-pane.active");
        let step_active_chap = step_active.attr("data-step");
        let chap = $(".smartwizard ul.nav li");

        chap.each(function() {
            let chap_link = $(this).find("a");

            if(chap_link.attr("data-step") == step_active_chap) {
                chap_link.parent().addClass("active");
                chap_link.addClass("active");
            }else {
                chap_link.parent().removeClass("active");
                chap_link.removeClass("active");
            }

            if(chap_link.attr("id") == step_active.attr("aria-labelledby")) {
                chap_link.attr("aria-selected", "true");
            }else {
                chap_link.attr("aria-selected", "false");
            }

        });
    });

    // Global Multi-step From Leave step Validation
    $(".smartwizard").on("leaveStep", function (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) {
        e.stopImmediatePropagation();

        let elmForm = $("#step-" + (nextStepIndex + 1));
        // stepDirection === 'forward' :- this condition allows to do the form validation
        // only on forward navigation, that makes easy navigation on backwards still do the validation when going next
        if (stepDirection === 'forward' && elmForm) {

            let tab = $(".tab-pane:visible");

            $(".smartwizard").parent().find("button[type=submit]").trigger("click");
            $(".tab-pane:visible").parent().find("label.error").remove();

            let tab_errors = tab.find(".field-validation-error");

            elmForm.find(".field-validation-error").html();
            elmForm.find(".field-validation-error").addClass("field-validation-valid").removeClass("field-validation-error");
            elmForm.find(".input-validation-error").removeClass("input-validation-error");

            if(tab_errors.length > 0) {
                return false;
            }

        }

    });

    // Global Multi-step From Submit validation
    $(".smartwizard").parent().find("button[type=submit]").on("click", function () {

        let tab = $(".tab-pane:visible");

        setTimeout(function() {
            let tab_errors = tab.find(".field-validation-error");

            if(tab_errors.length > 0) {
                tab.parent().animate({height: tab.outerHeight()});
            }
        }, 50);

    });

	$('.smartwizard input[type="radio"]').on("keydown", function(e){
        if (e.which == 37){
            return false;
        }
        else if (e.which == 39){
            return false;
        }
    });

    // Hidden result button on webpage
    $(".view-result").hide();


});